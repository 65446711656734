<template>
	<div class="home">
		<reserveMarketsVue />
	</div>
</template>

<script>
import reserveMarketsVue from "@/components/client/completeReserComponent.vue";
export default {
	name: "HomeView",
	components: {
		reserveMarketsVue,
	},
};
</script>
