<template>
	<div class="home">
		<offerComponentVue />
	</div>
</template>

<script>
import offerComponentVue from "@/components/client/pointsComponent.vue";
import axios from 'axios'
export default {
	name: "HomeView",
	components: {
		offerComponentVue,
	},
  methods: {
    async getProfile() {
       await axios.get('user/show-profile', {
         headers: {
            Authorization :`Bearer ${localStorage.getItem('token')}`
          }
       })
    },
  },
  mounted() {
    this.getProfile();
  }
};
</script>
