<template>
	<div class="home">
		<menueComponent />
	</div>
</template>

<script>
import menueComponent from "@/components/client/menueComponent.vue";
export default {
	name: "HomeView",
	components: {
		menueComponent,
	},
};
</script>
