export default {
  "nav": {
    "stores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متاجرنا"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف الحساب"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])},
    "rerserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجوزاتك"])},
    "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النقاط"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلباتك"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصي"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العروض والخصومات"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجز المقاعد"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اهلا"])},
    "join_partener": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انضم كشريك"])},
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تابعنا على"])}
  },
  "dialog": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول مطلوب"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن تقوم بتسجيل الدخول للمتابعة."])},
    "loginButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اغلاق"])}
  },
  "home": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث عن مدينة , عنوان "])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر موقعك"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اطلب قهوتك اونلاين في اي وقت"])},
    "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتشف منتجاتنا"])},
    "discoverDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عالم مدهش من النكهات والروائح واللذة المبهر"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتشف"])},
    "offersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تخفيضات مدهشة، فرصة للتوفير والاستمتاع بالتسوق"])},
    "offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتشف اعلاناتنا"])},
    "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اطلع علي المزيد"])},
    "whereBranches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اين توجد متاجرنا ؟"])},
    "branches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اين توجد فروعنا"])},
    "appDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصل على كل ما تحتاجه، متى ما تحتاجه"])},
    "App": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتشف تطبيقنا الجديد"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط و الأحكام"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الخصوصية"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
    "whoUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
    "aboutApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن تطبيقنا"])},
    "famous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشهر متاجرنا"])},
    "stores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متاجر"])},
    "not_cities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد مدن بالقرب منك"])}
  },
  "store": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد متاجر"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مغلق الان"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مفتوح"])},
    "near": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر اقرب مدينة لك"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر نوع المتجر"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث عن اسم المتجر"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع متاجرنا"])}
  },
  "single": {
    "rates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم"])},
    "opinions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اراء العملاء عن المتجر"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اطلب الان"])},
    "mostSell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاكثر مبيعا"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نبذه عن"])},
    "real": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ريال"])},
    "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" % على كل عملية شراء باكثر من"])},
    "getDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصل على خصم"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اطلع على قائمة المتجر"])}
  },
  "prod": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد منتجات"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضف للسلة"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل المنتجات"])},
    "mostRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاعلى تقييم"])},
    "depend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر حسب"])},
    "cat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر حسب التصنيف"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث عن اسم المنتج"])},
    "goCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اذهب الي السله"])}
  },
  "cart": {
    "simDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عادة ما يطلب المستخدمين ايضا هذه الطلبات"])},
    "similar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتجات متشابهة"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر موعد الاستلام"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر تاريخ الاستلام"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر الإجمالي"])},
    "chooseAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الاضافات"])},
    "chooseSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الاحجام"])},
    "prodTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي المنتج"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عربة التسوق"])}
  },
  "order": {
    "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيم المتجر"])},
    "payNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادفع الان"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحجم"])},
    "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكمية"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المنتج"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلباتك"])},
    "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع بالنقاط"])},
    "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع اونلاين"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الدفع"])},
    "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة الدفع"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر الإجمالي"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضريبة القيمة المضافة"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
    "receiveTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موعد الاستلام"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ انشاء الطلب"])},
    "receiveDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ استلام الطلب"])},
    "num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الطلب"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة الطلب"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الطلب"])},
    "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد عدد المقاعد"])},
    "reTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر التوقيت"])},
    "resDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر المعاد"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد الحجز"])},
    "offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العروض"])},
    "discounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخصومات"])},
    "noDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد خصومات الى الان"])},
    "noOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد عروض الى الان"])},
    "get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصل على خصومات فورية على اصناف مختارة"])},
    "mine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلباتي"])},
    "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتهية"])},
    "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جاري العمل عليها"])},
    "waitPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في انتظار الدفع"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في انتظار الموافقة"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد طلبات الى الان"])},
    "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الطلب"])},
    "clientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات العميل"])},
    "storeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المتجر"])},
    "reserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجوزاتي"])},
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقبولة"])},
    "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرفوضة"])},
    "reserveEmoty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد حجوزات الى الان"])},
    "reserveDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الحجز"])},
    "membersNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المقاعد"])},
    "reserveTimeDet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توقيت طلب الحجز"])},
    "reserveDetailDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
    "reserveDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الحجز"])}
  },
  "profile": {
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة المرور"])},
    "addressDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث عن مدينة , عنوان واي ما تريد"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الاكتروني"])},
    "emailDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى ادخال البريد الالكتروني"])},
    "phoneDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى ادخال رقم الجوال"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
    "lastDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى ادخال الاسم الاخير"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الاخير"])},
    "firstDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى ادخال الاسم الاخير"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الاول"])},
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصي"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك تعديل بياناتك الشخصية من هنا"])},
    "changeEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك تغيير كلمة المرور من هنا "])}
  },
  "auth": {
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعادة تعيين"])},
    "dontIden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير متطابق"])},
    "iden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متطابق"])},
    "confirmDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل تاكيد كلمة المرور"])},
    "confirmPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاكيد كلمة المرور"])},
    "newPassDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل كلمة المرور الجديدة"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديدة"])},
    "oldPassDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل كلمة المرور القديمة"])},
    "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور القديمة"])}
  },
  "be": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نَمِّ نشاطك التجاري عبر الإنترنت مع CoffeeKies !"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انضمَّ إلينا للوصول إلى المزيد من العملاء، زيادة الإيرادات، وتوسيع عملك عبر الإنترنت - قصة نجاحك تبدأ هنا."])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انضم الان"])},
    "why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لماذا يجب أن"])},
    "part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكون شريكًا معنا؟"])},
    "reach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صِل إلى المزيد من العملاء"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لدينا عدد كبير من العملاء في منطقتك الذين يرغبون في طلبمنك، وسنساعدك في توصيل القهوة إليهم في أسرع وقت"])},
    "money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اربح المزيد من المال"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" سنساعدك في خدمة المزيد من العملاء الجائعين دون زيادة عددالمقاعد في مطعمك، وسنضمن لك الحصول على الدفع الفوري"])},
    "grow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نَمِّ عملك التجاري"])},
    "tech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" زِد المبيعات، وصِل إلى المزيد من العملاء، أو سَوِّق عملك بشكل أفضل. نُقدِّم حلولًا لتحسين عملك لأن نجاحنا مرتبط  بنجاحك"])},
    "how": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف"])},
    "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنعمل معا ؟"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يَطلُب العميل طلب القهوة"])},
    "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" حيث يُرسل العميل الطلب عن طريق تطبيق CoffeeKies."])},
    "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تُحضِّر أنت الطلب"])},
    "make": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وسوف تَتَلقَّى إشعارًا للبدء في تحضير الطلب."])},
    "watch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شاهد عملك وهو ينمو"])},
    "catch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تَتَبَّع مبيعاتك، وإدارة الطلبات، والاستثمار في التسويق، والمزيد باستخدام بوابة متجرك الشخصية"])},
    "any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أي أسئلة؟ "])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نُجيبُك على كل شئ"])}
  },
  "suad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المملكه العربيه السعوديه"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعه"])},
  "CopyRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" جميع الحقوق محفوظة  "])},
  "con-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تتردد في التواصل معنا"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسال"])},
  "note-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك الاطلاع على اخر التحديثات من هنا"])},
  "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشعارات"])},
  "rateStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيم المتجر"])},
  "payNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اذهب للدفع"])},
  "cartEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السله فارغه"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])}
}