<template>
	<div class="home">
		<offerComponentVue />
	</div>
</template>

<script>
import offerComponentVue from "@/components/client/offerComponent.vue";
export default {
	name: "HomeView",
	components: {
		offerComponentVue,
	},
};
</script>
