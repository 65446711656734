<template>
	<div>
		<h4 class="text-center fw-bold">{{ $t("home.offers") }}</h4>
		<p class="fs-5 fw-6">
			{{ $t("home.offersDesc") }}
		</p>

		<section class="offers" v-if="ads.length > 0">
			<div class="container">
				<swiper
					:slides-per-view="1"
					:space-between="30"
					:modules="modules"
					:autoplay="{delay: 2500, disableOnInteraction: false}"
					class="h-100 w-100"
				>
					<swiper-slide class="h-100 w-100" style="aspect-ratio: 16 / 9" v-for="(ad, index) in ads" :key="index">
						<div class="offer h-100">
							<img :src="ad.image" alt="Offer Image" class="offer-image" style="aspect-ratio: 16 / 9" />
						</div>
					</swiper-slide>
				</swiper>
			</div>
		</section>
		<div v-if="ads.length == 0 && !loadingOffers">
			<div class="container">
				<Message severity="info"> {{ $t("order.noOffers") }} </Message>
			</div>
		</div>
	</div>
</template>

<script>
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from "swiper/vue";
import Message from "primevue/message";

// Import Swiper styles
import "swiper/css";
import {Autoplay} from "swiper";

export default {
	name: "MultivendorOffersComponent",
	components: {
		Swiper,
		SwiperSlide,
		Message,
	},
	setup() {
		return {
			modules: [Autoplay],
		};
	},
	data() {
		return {};
	},

	mounted() {},
	props: {
		ads: Array,
		loadingOffers: Boolean,
	},
	methods: {},
};
</script>

<style lang="scss" scoped>
.offer {
	display: flex;
	align-items: center;
	border: 1px solid #ccc;
	border-radius: 20px;
	padding: 10px;
	position: relative;
}
.offer_tag {
	background-color: red;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	left: 10px;
	top: 10px;
	width: 56px;
	border-radius: 4px;
	font-size: 13px;
	padding: 3px;
}
.offer-image {
	width: 100%;
	border-radius: 10px;
}
@media screen and (max-width: 768px) {
	.container {
		max-width: 100% !important;
	}
	
}
.container {
	max-width:50%;
}
.offer-details {
	flex-grow: 1;
}

.offer-title {
	font-size: 24px;
	margin-bottom: 10px;
}

.offer-description {
	font-size: 16px;
}
</style>
