<template>
	<section id="orders" class="mt-4">
		<div class="container">
			<!-- breadcrumb  -->
			<div class="breadcrumb d-flex">
				<router-link to="/" class="inActive"> {{ $t("profile.main") }}</router-link
				>&nbsp; - &nbsp;
				<p class="active mainColor">{{ $t("home.contact") }}</p>
			</div>

			<!-- content  -->
			<section>
				<h5 class="fw-bold red mb-3">{{ $t("con-desc") }}</h5>

				<form class="flex flex-wrap gap-3 p-fluid" @submit.prevent="sendMessage">
					<div class="row align-items-center">
						<div class="col-md-6 mb-2">
							<div class="row">
								<div class="col-md-12 mb-2">
									<!-- user name  -->
									<div class="position-relative flex-auto">
										<label for="integeronly" class="label text-end fw-bold d-block mb-2 fs-13"> {{ $t("name") }}</label>
										<input type="text" class="form-control" v-model="user_name" :placeholder="$t('name')" />
										<!-- icon  -->
										<!-- <div class="inputIcon">
                    <img :src="require('@/assets/imgs/user.svg')" alt="" />
                  </div> -->
									</div>
								</div>

								<div class="col-md-12 mb-2">
									<!-- phone  -->
									<div class="position-relative flex-auto defaultInput">
										<label for="integeronly" class="label fw-bold text-end d-block mb-2 fs-13">{{ $t("phone") }}</label>
										<!-- <InputNumber v-model="phone" class="defaultInput" inputId="integeronly" :placeholder="$t('auth.phonePlc')" /> -->
										<input
											type="tel"
											class="form-control phone-number-input"
											v-model="phone"
											:placeholder="$t('phone')"
										/>

										<!-- icon  -->
										<!-- <div class="inputIcon">
                    <img :src="require('@/assets/imgs/phone.svg')" alt="" />
                  </div> -->

										<!-- select phone  -->
										<!-- <Dropdown
                    v-model="selectedCity"
                    optionLabel="key"
                    class="w-full md:w-14rem"
                    @change="chooseCountry"
                  /> -->
									</div>
								</div>

								<div class="col-md-12 mb-2">
									<!-- user name  -->
									<div class="position-relative flex-auto">
										<label for="integeronly" class="label text-end fw-bold d-block mb-2 fs-13">
											{{ $t("address") }}</label
										>
										<input type="text" class="form-control" v-model="address" :placeholder="$t('address')" />
										<!-- icon  -->
										<!-- <div class="inputIcon">
                    <img :src="require('@/assets/imgs/user.svg')" alt="" />
                  </div> -->
									</div>
								</div>

								<div class="col-md-12 mb-2">
									<!-- user name  -->
									<div class="position-relative flex-auto">
										<label for="integeronly" class="label fw-bold text-end d-block mb-2 fs-13"
											>{{ $t("message") }}
										</label>
										<textarea name="" v-model="complaint" class="form-control" id="" cols="30" rows="5"></textarea>
									</div>
								</div>
							</div>

							<div class="d-flex justify-content-center">
								<button class="main_btn w-100 mx-auto pt-3 pb-3 fs-15" :disabled="disabled">
									<span v-if="!disabled">{{ $t("send") }} </span>
									<span class="visually-hidden" v-else>Loading...</span>
								</button>
							</div>
						</div>

						<div class="col-md-6 mb-2">
							<div class="job_image">
								<img class="w-100 h-100 lazy" :src="require('@/assets/imgs/logo.png')" alt="contact canvas" />
							</div>
						</div>
					</div>
				</form>
			</section>
		</div>
		<Toast />
	</section>
</template>

<script>
// import InputText from "primevue/inputtext";
// import InputNumber from 'primevue/inputnumber';
// import Dropdown from "primevue/dropdown";
// import Textarea from "primevue/textarea";
import Toast from "primevue/toast";
import axios from "axios";
// import { mapActions, mapState } from 'vuex';
export default {
	data() {
		return {
			selectedCity: {
				id: 8,
				name: this.$t("suad"),
				key: "+966",
			},
			disabled: false,
			user_name: null,
			phone: null,
			complaint: null,
			address: "",
		};
	},
	components: {
		// InputText,
		// // InputNumber,
		// // Dropdown,
		// Textarea,
		Toast,
	},
	// computed:{
	//     ...mapState(["common"])
	// },
	methods: {
		//   ...mapActions('common',['getCountries']),

		async sendMessage() {
			this.disabled = true;
			const fd = new FormData();
			fd.append("phone", this.phone);
			fd.append("name", this.user_name);
			fd.append("message", this.complaint);
			fd.append("title", this.address);
			await axios
				.post("user/contact-us", fd, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
						lang: "ar",
					},
				})
				.then((res) => {
					if (res.data.key === "success") {
						this.$toast.add({severity: "success", summary: res.data.msg, life: 3000});
						this.disabled = false;
						this.phone = null;
						this.complaint = null;
						this.user_name = null;
					} else {
						this.$toast.add({severity: "error", summary: res.data.msg, life: 3000});
						this.disabled = false;
					}
				});
			// // const res = await this.$store.dispatch('logic/sendCompaint', fd)
			// if( res.success == true ){

			// }else{
			// }
		},

		//   chooseCountry(){
		//         document.querySelector('.p-dropdown-label').innerHTML = this.selectedCity.key ;
		//     },
	},

	// mounted(){
	//     document.querySelector('.p-dropdown-label').innerHTML = this.selectedCity.key ;
	//     this.getCountries()
	// }
};
</script>
<style lang="scss"></style>
