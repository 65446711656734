<template>
  <div class="">
    <!-- first section  -->
    <div class="location-section">
      <section class="flex_center h-100">
        <div>
          <h1 class="mb-4 whiteColor">
            {{ $t('be.title') }}
          </h1>
          <h5 class="mb-4 whiteColor">
            {{ $t('be.desc') }}
          </h5>

          <a class="join-partener" target="_blank" href="https://vendor.trysnapus.com/login">
            {{ $t('be.join') }}
          </a>
        </div>
      </section>
    </div>

    <!-- second section  -->
    <section class="process-section reason-section pt-5 pb-5" id="process-section-110">
      <h3 class="process-section__heading">
        <lightning-formatted-rich-text
          class="slds-rich-text-editor__output fw-bold"
          lwc-4nfn2rc40ch-host=""
          ><span lwc-4nfn2rc40ch="" part="formatted-rich-text"
            >{{ $t('be.why') }}
            <span lwc-4nfn2rc40ch="" class="mainColor"> {{$t('be.part')}}</span></span
          ></lightning-formatted-rich-text
        >
      </h3>
      <div class="process-section__steps container">
        <div class="row">
            <div class="col-md-4">
                <div class="process-section__step-container">
          <div class="process-section__step">
            <div class="process-section__step__count">
              <figure>
                <img
                  :src="require('@/assets/imgs/cake.jpg')"
                  class="partener_image"
                />
              </figure>
            </div>
            <div class="process-section__step__content">
              <h5>{{ $t('be.reach') }}</h5>
              <p>
                <lightning-formatted-rich-text
                  class="slds-rich-text-editor__output"
                  lwc-4nfn2rc40ch-host=""
                  ><span lwc-4nfn2rc40ch="" part="formatted-rich-text"
                    >
                    {{ $t('be.clients') }}
                     </span
                  ></lightning-formatted-rich-text
                >
              </p>
            </div>
          </div>
        </div>
            </div>
            <div class="col-md-4">
                 <div class="process-section__step-container">
          <div class="process-section__step">
            <div class="process-section__step__count">
              <figure>
                <img
                  :src="require('@/assets/imgs/coffee.webp')"
                  class="partener_image"
                />
                </figure>
            </div>
            <div class="process-section__step__content">
              <h5>{{ $t('be.money') }}</h5>
              <p>
                <lightning-formatted-rich-text
                  class="slds-rich-text-editor__output"
                  lwc-4nfn2rc40ch-host=""
                  ><span lwc-4nfn2rc40ch="" part="formatted-rich-text"
                    > 
                    {{ $t('be.help') }}
                    </span
                  ></lightning-formatted-rich-text
                >
              </p>
            </div>
          </div>
        </div>
            </div>

            <div class="col-md-4">
                 <div class="process-section__step-container">
          <div class="process-section__step">
            <div class="process-section__step__count">
             <figure>
                <img
                  :src="require('@/assets/imgs/coffee1.webp')"
                  class="partener_image"
                />
                </figure>
            </div>
            <div class="process-section__step__content">
              <h5>{{ $t('be.grow') }}</h5>
              <p>
                <lightning-formatted-rich-text
                  class="slds-rich-text-editor__output"
                  lwc-4nfn2rc40ch-host=""
                  ><span lwc-4nfn2rc40ch="" part="formatted-rich-text"
                    >
                   {{ $t('be.tech') }}
                    </span
                  ></lightning-formatted-rich-text
                >
              </p>
            </div>
          </div>
        </div>
            </div>
        </div>
        
       
       
      </div>
    </section>
    <!-- third section  -->
    <section class="process-section reason-section pt-5 pb-5" id="process-section-110">
      <h3 class="process-section__heading">
        <lightning-formatted-rich-text
          class="slds-rich-text-editor__output fw-bold"
          lwc-4nfn2rc40ch-host=""
          ><span lwc-4nfn2rc40ch="" part="formatted-rich-text"
            >{{ $t('be.how') }}
            <span lwc-4nfn2rc40ch="" class="mainColor"> {{ $t('be.work') }}</span></span
          ></lightning-formatted-rich-text
        >
      </h3>
      <div class="process-section__steps container">
        <div class="row">
            <div class="col-md-4">
                <div class="process-section__step-container work" >
          <div class="process-section__step">
            <div class="process-section__step__count">
              <figure>
                <img
                  :src="require('@/assets/imgs/1.svg')"
                  class="partener_image"
                />
              </figure>
            </div>
            <div class="process-section__step__content">
              <h5>{{ $t('be.order') }}</h5>
              <p>
                <lightning-formatted-rich-text
                  class="slds-rich-text-editor__output"
                  lwc-4nfn2rc40ch-host=""
                  ><span lwc-4nfn2rc40ch="" part="formatted-rich-text"
                    >
{{  $t('be.app') }}
                    </span
                  ></lightning-formatted-rich-text
                >
              </p>
            </div>
          </div>
        </div>
            </div>
            <div class="col-md-4">
                 <div class="process-section__step-container work">
          <div class="process-section__step">
            <div class="process-section__step__count">
              <figure>
                <img
                  :src="require('@/assets/imgs/3.svg')"
                  class="partener_image"
                />
                </figure>
            </div>
            <div class="process-section__step__content">
              <h5>
                {{ $t('be.ready') }}

              </h5>
              <p>
                <lightning-formatted-rich-text
                  class="slds-rich-text-editor__output"
                  lwc-4nfn2rc40ch-host=""
                  ><span lwc-4nfn2rc40ch="" part="formatted-rich-text"
                    >
                  {{ $t('be.make') }}
                    </span
                  ></lightning-formatted-rich-text
                >
              </p>
            </div>
          </div>
        </div>
            </div>

            <div class="col-md-4">
                 <div class="process-section__step-container work">
          <div class="process-section__step">
            <div class="process-section__step__count">
             <figure>
                <img
                  :src="require('@/assets/imgs/2.svg')"
                  class="partener_image"
                />
                </figure>
            </div>
            <div class="process-section__step__content">
              <h5>{{ $t('be.watch') }}
</h5>
              <p>
                <lightning-formatted-rich-text
                  class="slds-rich-text-editor__output"
                  lwc-4nfn2rc40ch-host=""
                  ><span lwc-4nfn2rc40ch="" part="formatted-rich-text"
                    >
                    {{ $t('be.catch') }}


                    </span
                  ></lightning-formatted-rich-text
                >
              </p>
            </div>
          </div>
        </div>
            </div>
        </div>
        
       
       
      </div>
    </section>
    <!-- fourd section  -->
    <section class="process-section reason-section pt-5 pb-5" id="process-section-110">
      <h3 class="process-section__heading">
        <lightning-formatted-rich-text
          class="slds-rich-text-editor__output fw-bold"
          lwc-4nfn2rc40ch-host=""
          ><span lwc-4nfn2rc40ch="" part="formatted-rich-text"
            >
            {{ $t('be.any') }}


            <span lwc-4nfn2rc40ch="" class="mainColor"> 
              {{ $t('be.answer') }}
            </span></span
          ></lightning-formatted-rich-text
        >
      </h3>
      <div class="process-section__steps container">
        <div class="row">
                          <Accordion :activeIndex="0">
                <AccordionTab v-for="faq in faqs" :key="faq.id" :header="faq.question">
                  
                  <h6 class="text-end">
                    {{  faq.answer  }}
                  </h6>
                </AccordionTab>
                
       
              </Accordion>
        </div>
        
       
       
      </div>
    </section>
  </div>
</template>

<script>
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import axios from "axios";
export default {
  name: "MultivendorBecomePartener",

  data() {
    return {
      faqs : []
    };
  },

  mounted() {
    this.getFaqs();
  },

  methods: {
    async getFaqs() {
      await axios.get('store/fqs')
        .then((res) => {
          this.faqs = res.data.data;
      } )
    }
  },
  components: {
    Accordion,
    AccordionTab
  }
};
</script>

<style>
  .work{
    background-color: #F7F7F7;
    padding: 20px;
    border-radius: 10px;
  }
  .work .partener_image{
    width: 90px;
    height: 90px;
    object-fit: contain;
    border-radius: 0px !important;
  }
    .partener_image{
        width: 130px;
        height: 130px;
        object-fit: cover;
        border-radius: 50%;
    }
    .join-partener{
          background: #734b21;
    color: #fff;
    text-decoration: none;
    width: 131px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 10px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 18px;
    }
</style>
