// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getMessaging} from "firebase/messaging";

// import '../public/firebase-messaging-sw'

const firebaseConfig = {
	apiKey: "AIzaSyA1GSEudH0g7llZ6nhbjn66VPc_Lo_V2UM",
	authDomain: "coffeekies-f5e03.firebaseapp.com",
	projectId: "coffeekies-f5e03",
	storageBucket: "coffeekies-f5e03.appspot.com",
	messagingSenderId: "1086094672263",
	appId: "1:1086094672263:web:13810e991dda1ddc671322",
	measurementId: "G-Q13H7862ZW",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const messaging = getMessaging(app);

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('../public/firebase-messaging-sw');
//   });
// }
