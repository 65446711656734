<template>
	<div class="default-layout">
		<div> 
			<headerComponentVue />
			<router-view />
		</div>
		<footerComponentVue />
	</div>
</template>

<script>
import headerComponentVue from "@/components/client/headerComponent.vue";
import footerComponentVue from "@/components/client/footerComponent.vue";

export default {
	name: "DefaultLayout",
	components: {
		headerComponentVue,
		footerComponentVue,
	},
};
</script>

<style scoped>
.default-layout {
	display: flex;
	overflow: hidden;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
}
</style>
