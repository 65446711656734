<template>
	<div class="home">
		<privacyComponent />
	</div>
</template>

<script>
import privacyComponent from "@/components/client/contactComponent.vue";
export default {
	name: "HomeView",
	components: {
		privacyComponent,
	},
};
</script>
