<template>
	<section id="orders" class="mt-4">
		<div class="container">
			<!-- breadcrumb  -->
			<div class="breadcrumb d-flex">
				<router-link to="/" class="inActive"> {{ $t("profile.main") }} </router-link>&nbsp; - &nbsp;
				<p class="active mainColor">{{ $t("nav.points") }}</p>
			</div>

			<!-- content  -->
			<section class="text-center">
				<h3 class="fw-bold mainColor red mb-3">{{ $t("nav.points") }}</h3>

				<!-- Centered Card -->
				<div class="d-flex justify-content-center mt-4">
						<section class="single_card p-4 d-flex justify-content-center">
							<!-- Card Content -->
								<h1 class="fw-bold mainColor d-flex align-items-center m-0 mx-3">
									{{ points && points.length > 0 ? points : 0 }} 
                </h1>
								<span class="profile_icon flex_center">
							<i class="fa fa-money-bill" style="font-size: x-large;"></i>
						</span>							
						</section>
				</div>
			</section>
		</div>
	</section>
</template>

<script>
import axios from "axios";

export default {
	data() {
		return {
			points: 0, // Initialize points as 0 to avoid null/undefined issues
			load: true,
			LenghtZero: false,
			type: "pending",
		};
	},
	methods: {
		// get user profile data
		async getProfile() {
			try {
				const res = await axios.get('user/show-profile', {
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`
					}
				});
				if (res.data.key === 'success') {
					this.points = res.data.data.scores || 0; // Default to 0 if points is undefined
				}
			} catch (error) {
				console.error("Failed to fetch profile:", error);
			}
		}
	},
	mounted() {
		this.getProfile();
	}
};
</script>

<style lang="scss" scoped>
.single_card {
	box-shadow: 0px 0px 10px #33333325;
	border: 1px solid #ccc;
	border-radius: 10px;
	padding: 20px;
	background-color: #fff;
	text-align: center;
	width: 100%;
	height: 150px;
	max-width: 300px;
}
</style>
