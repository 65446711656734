<template>
	<div class="home">
		<completeOrder />
	</div>
</template>

<script>
import completeOrder from "@/components/client/completeOrder.vue";
export default {
	name: "HomeView",
	components: {
		completeOrder,
	},
};
</script>
