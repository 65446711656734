<template>
	<div class="home">
		<marketsComponentVue />
		<branchesComponentVue />
		<downloadAppVue />
	</div>
</template>

<script>
import downloadAppVue from "@/components/client/downloadApp.vue";
import marketsComponentVue from "@/components/client/marketsComponent.vue";
export default {
	name: "HomeView",
	components: {
		downloadAppVue,
		marketsComponentVue,
	},
};
</script>
