<template>
	<div class="home">
		<cartComponent />
	</div>
</template>

<script>
import cartComponent from "@/components/client/profileComponent.vue";
export default {
	name: "HomeView",
	components: {
		cartComponent,
	},
};
</script>
