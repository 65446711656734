<template>
	<div class="home">
		<marketsComponentVue />
		<singleMarketComponent />
		<downloadAppVue />
	</div>
</template>

<script>
import downloadAppVue from "@/components/client/downloadApp.vue";
import singleMarketComponent from "@/components/client/singleMarketComponent.vue";
export default {
	name: "HomeView",
	components: {
		downloadAppVue,
		singleMarketComponent,
	},
};
</script>
